/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Slideshow, Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kavárna MANETKA - Akce"} description={"Akce pořádané kavárnou a cukrárnou"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Slideshow style={{"backgroundColor":"rgba(34,25,77,0)"}} name={"bgrtewhx8a"} infinite={true} transitionDuration={0}>
          
          <Column className="css-y3emnb css-42e4bw js-anim  --anim4 --full" style={{"paddingBottom":1,"paddingTop":110}} fullscreen={true} anim={"4"} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/bbbc7b1ed3874d7eaae2d1ac1d4d3802_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/bbbc7b1ed3874d7eaae2d1ac1d4d3802_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/bbbc7b1ed3874d7eaae2d1ac1d4d3802_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/bbbc7b1ed3874d7eaae2d1ac1d4d3802_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/bbbc7b1ed3874d7eaae2d1ac1d4d3802_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/bbbc7b1ed3874d7eaae2d1ac1d4d3802_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/bbbc7b1ed3874d7eaae2d1ac1d4d3802_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/bbbc7b1ed3874d7eaae2d1ac1d4d3802_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":362}}>
                
                <Title className="title-box fs--102 title-box--invert" content={"<span style=\"color: var(--white);\">Rozsvícení vánočního stromečku</span>"}>
                </Title>

                <Text className="text-box fs--48 text-box--invert" style={{"maxWidth":827}} content={"sobota 30. listopadu od 17 hodin"}>
                </Text>

                <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 btn-box--center fs--22" style={{"backgroundColor":"var(--white)","marginTop":60,"marginBottom":14}} content={"<span style=\"color: rgb(88, 92, 99);\">více informací</span><br>"} use={"page"} href={"/akce#stromecek_program"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="css-16i1phb js-anim  --anim4 --full" style={{"paddingBottom":0,"paddingTop":0}} fullscreen={true} anim={"4"} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/a21e2875ca7740f5b32f16b0d3b8937d_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/a21e2875ca7740f5b32f16b0d3b8937d_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/a21e2875ca7740f5b32f16b0d3b8937d_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/a21e2875ca7740f5b32f16b0d3b8937d_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/a21e2875ca7740f5b32f16b0d3b8937d_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/a21e2875ca7740f5b32f16b0d3b8937d_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/a21e2875ca7740f5b32f16b0d3b8937d_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/a21e2875ca7740f5b32f16b0d3b8937d_s=3000x_.jpg);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" style={{"paddingBottom":9,"paddingLeft":0,"paddingRight":0,"paddingTop":703}} columns={"1"}>
              
              <ColumnWrapper >
                
                <Title className="title-box fs--102 title-box--invert" content={"<span style=\"color: var(--white);\">Mikulášská nadílka</span>"}>
                </Title>

                <Text className="text-box fs--48 text-box--invert" style={{"maxWidth":827}} content={"čtvrtek 5. prosince od 16 hodin"}>
                </Text>

                <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 btn-box--center fs--22" style={{"backgroundColor":"var(--white)","marginTop":60,"marginBottom":14}} content={"<span style=\"color: rgb(88, 92, 99);\">více informací</span><br>"} use={"page"} href={"/akce#mikulasska_nadilka"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column style={{"paddingTop":134,"paddingBottom":132,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"stromecek_program"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":2074,"paddingRight":39}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--43" style={{"marginBottom":41}} content={"<span style=\"color: var(--black);\">Náš tip na čtvrtek 5. prosince 2024</span>"}>
              </Subtitle>

              <Title className="title-box ff--3 fs--72" style={{"maxWidth":694,"marginTop":0,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"color: var(--black);\">Rozsvícení vánočního\nstromečku</span><br>"}>
              </Title>

              <Text className="text-box text-box--center fs--20 w--500" style={{"maxWidth":918,"paddingLeft":0,"marginBottom":19,"paddingRight":0,"paddingBottom":2,"marginTop":0}} content={"<span style=\"font-weight: bold;\"><br></span>Srdečně Vás zveme k nám do kavárny na rozsvícení vánočního stromečku a také na krátký program, který se bude konat v naší zahradě poslední den před adventem. Přijďte si s námi užít pravou vánoční atmosféru.<br><br><span style=\"font-weight: bold;\">17:00</span><br>Sejdeme se všichni u nás na zahradě u kavárny Manetka a společně si v doprovodu hudby zazpíváme vánoční koledy.<br>K dispozici bude speciálně připravený vánoční fotokoutek a pozor!<br><br><span style=\"font-weight: bold;\">DĚTI, NEZAPOMEŇTE SI S SEBOU PŘINÉST<br>DOPIS PRO JEŽÍŠKA!<br>BUDETE HO MOCI VHODIT DO SPECIÁLNÍ JEŽÍŠKOVSKÉ<br>POŠTOVNÍ&nbsp;SCHRÁNKY!&nbsp;</span><br><br><span style=\"font-weight: bold;\">18:00</span><br>Slavnostní rozsvícení vánočního stromečku!<br><br>Připravili jsme pro Vás tradiční teplé nápoje a občerstvení, <br>a letos i jednu novinku:<br><br><span style=\"font-weight: bold;\">likéry s originální manetkovskou recepturou</span>,<br>které můžete ochutnat a zakoupit samostatně&nbsp;či v dárkové sadě jako luxusní vánoční dárek&nbsp;pro Vaše blízké.<br>A samozřejmě také vánoční vouchery.<br>Moc se na Vás těšíme!<br><br>(vstup na akci je zdarma)<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn4 btn-box--shape5 btn-box--cColor2 btn-box--shadow5 fs--20 w--600" style={{"backgroundColor":"rgba(69,68,250,1)"}} use={"page"} href={"/akce#rychla_rezervace"} content={"<span style=\"color: var(--white);\">Rezervovat si stůl</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":950}}>
              
              <Image className="--shape4 --shadow4" alt={"Stromecek_letak"} src={"https://cdn.swbpg.com/t/34102/31b4c29212c5458dbf806cd743c70075_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/31b4c29212c5458dbf806cd743c70075_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/31b4c29212c5458dbf806cd743c70075_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/31b4c29212c5458dbf806cd743c70075_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/31b4c29212c5458dbf806cd743c70075_s=1400x_.jpg 1400w"} lightbox={true} description={"Hurááá prázdniny"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"mikulasska_nadilka"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":1822}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/8ef375919c63409e94862e7060ef6806_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/8ef375919c63409e94862e7060ef6806_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/8ef375919c63409e94862e7060ef6806_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/8ef375919c63409e94862e7060ef6806_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/8ef375919c63409e94862e7060ef6806_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--43" content={"<span style=\"color: var(--black);\">Náš tip na čtvrtek 5. prosince 2024</span>"}>
              </Subtitle>

              <Title className="title-box fs--72" content={"<span style=\"color: var(--black);\">Mikulášská nadílka</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":783}} content={"Přátelé, malí i velcí hříšníci, hodní andílci a rodiče!\nVe čtvrtek k nám zavítá Svatý Mikuláš s inspekcí z pekla a nebeskými anděly. Hodným dětem předá dárečky a uličníky pořádně prožene!\nKdopak si k nám přijde pro dáreček, andělské či čertovské líčení, či zatancovat s čertíky?<br><br><span style=\"font-weight: bold;\">16:00</span><br>Sraz v kavárně Manetka, kde se budou konat andělské dílničky, můžete si vyrobit papírové ozdoby s mikulášskou či vánoční tématikou,<br>nechat se nalíčit přímo andělsky nebo čertovsky!<br>A pak už budeme čekat na příchod<br>Svatého Mikuláše s andílky a čerty :-) Těšíte se?<br><br><span style=\"font-weight: bold;\">17:00</span><br>Příchod Mikuláše s družinou a nadílka dárečků hodným dětem.<br>Přijít mohou samozřejmě všechny děti, čert je určitě prohlédne a třeba budou muset něco zazpívat či zarecitovat!<br>Ti, kteří zde budou mít zaplacené předání balíčku, budou mít od nás číslo a Mikuláš jim předá dáreček.<br>Se všemi našimi bytostmi se můžete vyfotit a na závěr i krátce zatancovat<br>na naší \"rohaté dětské diskotéce\".<br><br>Těšíte se? My hodně! Na čerty, na andílky a na všechny děti!&nbsp;<br>Tak nechte strach doma a přijďte ;-)<br><br>"}>
              </Text>

              <Text className="text-box text-box--center fs--14" content={"<span style=\"text-decoration-line: underline;\">balíčky:\n</span><br>Předání balíčků kavárně se bude konat od 1. do 4. prosince. Platí se na pokladně při donesení balíčku. Rodiče při předání vyplní do sešitu krátce jméno a příjmení dítěte, 1-3 dětské hříchy a 1-3 pochvaly pro své děti a dostanou pro každé dítě číslo, díky kterému my poznáme, který balíček ke komu patří. Takže pozor:\n<br><span style=\"font-weight: bold;\">1 dítě = 1 balíček = 1 číslo\n</span><br>(pokud máte 2 děti, dostanete 2 čísla, apod...)\n<br><span style=\"font-weight: bold;\">1 balíček = 150 Kč za předání\n</span><br>(platí se v kavárně u pokladny při předání balíčku obsluze kavárny.)\n<br><br>&nbsp;PROSÍME, NEDÁVEJTE DO BALÍČKŮ NIC, CO BY SE MOHLO ZKAZIT!\nNEBO HO RADĚJI PŘINESTE VEČER PŘED NADÍLKOU.\nNEDISPONUJEME BOHUŽEL STUDENÝMI SKLADOVACÍMI PROSTORY.\nDĚKUJEME ZA POCHOPENÍ.&nbsp;"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn4 btn-box--shape5 btn-box--cColor2 btn-box--shadow5 fs--20 w--600" style={{"backgroundColor":"rgba(69,68,250,1)","marginTop":83}} use={"page"} href={"/akce#rychla_rezervace"} content={"<span style=\"color: var(--white);\">Rezervovat si stůl</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1orm3ur css-42e4bw --parallax pb--80 pt--80" name={"rychla_rezervace"} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --shape4 el--1 pb--20 pt--20" style={{"backgroundColor":"rgba(0,0,0,0.6)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Rychlá rezervace</span>"}>
              </Title>

              <Title className="title-box title-box--left fs--43 w--400 ls--02 lh--16 pb--12 pt--16" content={"<span style=\"color: white;\">kavárna a cukrárna MANETKA<br><a href=\"tel:+420773300833\">+420 773 300 833</a><br><a href=\"mailto:kavarna@manetka.cz?subject=Rezerva%C4%8Dn%C3%AD+formul%C3%A1%C5%99+z+webu\" target=\"_blank\">kavarna@manetka.cz</a><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"rgba(135,140,149,1)","paddingTop":83,"paddingBottom":80}} name={"galerieakci"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center ff--2 fs--86 w--400" content={"<span style=\"color: var(--white);\">Galerie akcí</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0 pt--0">
              
              <Text className="text-box text-box--center fs--26" style={{"maxWidth":664}} content={"<span style=\"color: var(--white);\">Prohlédněte si galerii proběhlých akcí. Tohle vše u nás můžete zažít! Stačí sledovat naše webové stránky či sociální sítě ;-)</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"maxWidth":"","paddingBottom":0,"marginTop":63,"paddingTop":0}} columns={"4"}>
            
            <ColumnWrapper className="--hvr5 --left">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/ef58e52b449a4669b1c56654ef4d0e86_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/ef58e52b449a4669b1c56654ef4d0e86_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/ef58e52b449a4669b1c56654ef4d0e86_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/ef58e52b449a4669b1c56654ef4d0e86_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5 --left">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/125ea890b1f8487b822bfc5011374146_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/125ea890b1f8487b822bfc5011374146_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/125ea890b1f8487b822bfc5011374146_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/125ea890b1f8487b822bfc5011374146_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":306}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":306}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/a618fb6f598b4c31951b5329f38abe37_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/a618fb6f598b4c31951b5329f38abe37_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/a618fb6f598b4c31951b5329f38abe37_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/a618fb6f598b4c31951b5329f38abe37_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/7cb360b5e8c846c2a81ef4ecc647fd7e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/7cb360b5e8c846c2a81ef4ecc647fd7e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/7cb360b5e8c846c2a81ef4ecc647fd7e_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/7cb360b5e8c846c2a81ef4ecc647fd7e_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mt--50 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="mt--0 pt--0">
              
              <Button className="btn-box btn-box--hvr4 btn-box--shape5 btn-box--cColor1 btn-box--shadow5" style={{"backgroundColor":"var(--white)"}} content={"<span style=\"color: var(--color-custom-1--15);\">Celá galerie akcí</span>"} use={"page"} href={"/fotogalerie#foto_akce"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"rgba(135,140,149,1)","paddingBottom":78,"paddingTop":2,"marginBottom":0}} name={"c1up5ah2y3h"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Spolupracujeme...</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--white);\">Kudy z nudy</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Nově spolupracujeme s portálem Kudy z nudy, kde naleznete projekt České centrály cestovního ruchu – CzechTourism, jehož cílem je podpora domácího cestovního ruchu. Kudy z nudy nabízí komplexní nabídku pro každého cestovatele.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5" style={{"backgroundColor":"var(--white)"}} content={"<span style=\"color: rgb(104, 102, 108);\">Kudy z nudy</span>"} url={"https://www.kudyznudy.cz/"} href={"https://www.kudyznudy.cz/"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":104,"paddingBottom":103}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":1406,"paddingLeft":0,"marginBottom":0}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">WEB</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"marginTop":14,"marginBottom":0,"paddingBottom":30}} content={"<span style=\"color: var(--black);\"><span style=\"text-decoration-line: underline;\"><a href=\"/#bcckfdbfejb\" style=\"\">O nás</a></span><a href=\"/menu#menu_uvod\"><br>Stálé menu<br></a><a href=\"/akce#j40igf0u8gp\">Akce</a><a href=\"/menu#menu_uvod\" style=\"\"><br></a><a href=\"/salonek#salonek_uvod\" style=\"\">Salónek</a><a href=\"/menu#menu_uvod\"><br></a><a href=\"/kontakt#kontakty\" style=\"\">Kontakt</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">KAVÁRNA A CUKRÁRNA MANETKA</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"maxWidth":396,"marginTop":13,"paddingRight":0,"paddingBottom":0}} content={"<span style=\"color: var(--black);\">Manětínská 30, Plzeň<br>tel: 773 300 833, email: kavarna@manetka.cz</span><br><span style=\"text-decoration-line: underline; font-weight: bold; color: var(--black);\"><a href=\"https://www.google.com/maps/place/Manetka+cafe/@49.7800782,13.3664327,3a,90y,117.55h,89.06t/data=!3m7!1e1!3m5!1sAF1QipPbAylCirgJw9XZmzsz7O3ipPU8vZNmJViK7vnr!2e10!3e12!7i11000!8i5500!4m9!3m8!1s0x470af33bb34100a5:0x17c49104135d9431!8m2!3d49.7798029!4d13.3665938!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11l2m45152?entry=ttu\" target=\"_blank\" style=\"\">VIRTUÁLNÍ PROHLÍDKA KAVÁRNY</a></span><br><br><span style=\"font-weight: bold;\">OTEVÍRACÍ DOBA KAVÁRNY</span><br>Po - Pá&nbsp; 08:00 - 21:00<br>So - Ne 14:00 - 21:00<br>"}>
              </Text>

              <Image style={{"maxWidth":124,"marginTop":33}} alt={""} src={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png"} use={"page"} href={"/#bcckfdbfejb"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=350x_.png 350w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=660x_.png 660w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png 860w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">SLEDUJ NÁS</span>"}>
              </Title>

              <Image style={{"maxWidth":64,"marginTop":31}} alt={"Facebook"} src={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png"} url={"https://www.facebook.com/Manetkacafe"} use={"url"} href={"https://www.facebook.com/Manetkacafe"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png 350w"} lightbox={false}>
              </Image>

              <Image style={{"maxWidth":64,"marginTop":35}} alt={"Instagram"} src={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png"} url={"https://www.instagram.com/manetka_cafe/"} use={"url"} href={"https://www.instagram.com/manetka_cafe/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png 350w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}